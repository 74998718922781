import React, { Fragment } from "react"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import { ThemeProvider } from "@mui/material/styles"
import theme from "../../theme"
import AboutHeroHeader from "../../components/HeroHeader/About/About"
import Fast from "../../components/Fast/Fast"
import LeaderShip from "../../components/LeaderShip/LeaderShip"
import ApaceFamily from "../../components/ApaceFamily/ApaceFamily"
import ApaceTheme from "../../components/ApaceTheme/ApaceTheme"
import { Container } from "@mui/material"
import "../../styles/home.scss"

const about = () => {
  return (
    <div className="new_home_container">
      <ThemeProvider theme={theme}>
        <Navbar bgColor="#F7FDFF" />
        <Fast />
        <AboutHeroHeader top="0" />
        {/* <LeaderShip /> */}
        <ApaceFamily />
        <ApaceTheme />
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default about
